<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    lazy
    transition="scale-transition"
    offset-y
    full-width
    max-width="290px"
    min-width="290px"
    :openOnClick="!disabled"
  >
    <v-text-field
      slot="activator"
      v-model="dateFormatted"
      :label="title"
      append-icon="event"
      :disabled="disabled"
      @blur="date = parseDate(dateFormatted)"
    ></v-text-field>
    <v-date-picker
      locale="pt"
      v-model="date"
      :disabled="disabled"
      @input="menu = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: ["value", "title", "disabled"],
  data() {
    return {
      menu: false,
      date: null, // yyyy-mm-dd
      dateFormatted: null, // dd-mm-yyyy
    };
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
      this.$emit("input", this.dateFormatted);
    },
  },
  methods: {
    formatDate(date) {
      // retrive always in mode dd-MM-yyyy
      if (!date) return null;
      const [day, month, year] = date.split(" ")[0].split("-");
      if (year.length === 4) return `${day}-${month}-${year}`;
      return `${year}-${month}-${day}`;
    },
    parseDate(date) {
      // retrive always in mode yyyy-MM-dd
      if (!date) return null;
      const [day, month, year] = date.split(" ")[0].split("-");
      if (year.length === 2)
        return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    clear() {
      this.date = null;
    },
  },
  created() {
    if (this.value) {
      this.date = this.parseDate(this.value);
      this.dateFormatted = this.formatDate(this.date);
    }
  },
};
</script>

<style scoped lang="scss"></style>
